/* Center the login form on the screen */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff; /* White background */
  margin: 0;
}

/* Card Style for the Login Form */
.login-card {
  width: 350px;
  height: 350px; /* Square shape */
  padding: 20px;
  border-radius: 12px; /* Slightly rounded edges */
  background-color: #f9f9f9;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Heading Style */
.login-heading {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #007bff; /* Primary color */
}

/* Subtext Style */
.login-subtext {
  font-size: 14px;
  color: #6c757d; /* Muted color */
  margin-bottom: 20px;
}

/* Google Login Button */
.google-login-button {
  background: none; /* Removes background color */
  border: none; /* Removes any border around the button */
  padding: 0; /* Removes extra padding */
  cursor: pointer;
  display: inline-block;
  transition: transform 0.2s ease-in-out;
}

/* Add hover effect for better interactivity */
.google-login-button:hover {
  transform: scale(1.05); /* Slight zoom-in effect */
}

/* Google Logo Image */
.google-login-image {
  width: 100%; /* Adjust to fit the button size */
  max-width: 300px; /* Set maximum width */
  height: auto; /* Maintain aspect ratio */
  border: none; /* Removes border around the image */
  display: block; /* Ensures it behaves as a block-level element */
}

/* Responsive Design */
@media (max-width: 768px) {
  .login-card {
    width: 300px;
    height: 300px; /* Maintain square shape */
  }

  .login-heading {
    font-size: 20px;
  }

  .google-login-image {
    max-width: 250px; /* Scale down for smaller screens */
  }
}

